import feathers from "Api/Feathers";
import { ResponseForbiddenEvent } from "declarations";

export const getForbiddenEvent = (
  limit: number,
  skip: number,
  plaques?: string[],
  geofences?: string[],
  clients?: string[],
  dateRange?: string[]
): Promise<ResponseForbiddenEvent> =>
  feathers.service("forbidden-event").find({
    query: {
      $limit: limit,
      $skip: skip,
      $sort: {
        date: -1,
      },
      "machine.plaque": {
        $in: plaques,
      },
      "machine.client": {
        $in: clients,
      },
      "geofence.name": {
        $in: geofences,
      },
      $and: [
        {
          date: {
            $gte: dateRange && dateRange[0],
          },
        },
        {
          date: {
            $lte: dateRange && dateRange[1],
          },
        },
      ],
    },
  });

export const getForbiddenEventCSV = (
  fields: string[][],
  limit: number,
  skip: number,
  plaques?: string[],
  geofences?: string[],
  dateRange?: string[]
) =>
  feathers.service("forbidden-event").find({
    query: {
      csv: true,
      fields,
      $limit: limit,
      $skip: skip,
      $sort: {
        date: -1,
      },
      "machine.plaque": {
        $in: plaques,
      },
      "geofence.name": {
        $in: geofences,
      },
      $and: [
        {
          date: {
            $gte: dateRange && dateRange[0],
          },
        },
        {
          date: {
            $lte: dateRange && dateRange[1],
          },
        },
      ],
    },
  });
