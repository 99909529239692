/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { DemoDirection } from "declarations";
import feathers from "./Feathers";

const DemoDirectionsService = feathers.service("demo-directions");

export const getDemoDirections = (
  search?: string
): Promise<DemoDirection[]> =>
  DemoDirectionsService.find({
    query: {
      name: {
        $search: search,
      },
    },
  });

export const postDemoDirection = (newDemoDirection: DemoDirection) =>
  DemoDirectionsService.create(newDemoDirection);

export const updateDemoDirection = (
  demoDirection: DemoDirection
): Promise<DemoDirection> => {
  const id = demoDirection._id;

  delete demoDirection._id;
  const response = DemoDirectionsService.update(id, { ...demoDirection });

  return response;
};

export const deleteDemoDirection = (id: string) =>
  DemoDirectionsService.remove(id);
