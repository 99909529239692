import styled, { css } from "styled-components";
import BoardList from "Components/BoardList";

export const WrapperList = styled(BoardList)<{ position: "Left" | "Right" }>`
  position: fixed;
  top: 10vh;
  ${(props) =>
    props.position === "Left"
      ? css`
          left: 7vw;
        `
      : css`
          right: 7vw;
        `}

  z-index: 801;
  height: 80vh;
`;

export const Label = styled.p`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  font-size: 14px;
`;
