import PrimaryButton from "BaseComponents/Buttons/PrimaryButton";
import SecondaryButton from "BaseComponents/Buttons/SecondaryButton";
import styled from "styled-components";

export const Wrapper = styled.div`
  background: white 0% 0% no-repeat padding-box;
  border-radius: 8px;
  box-shadow: 0px 0px 20px ${(props) => props.theme.colors.layout.shadow};
  overflow: auto;

  &:focus {
    outline: none;
  }
`;

export const Footer = styled.div`
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: row-reverse;
  border-top: 1px solid ${(props) => props.theme.colors.layout.whiteTertiary};
`;

export const ButtonSubmit = styled(PrimaryButton)`
  width: 140px;
  height: 40px;
`;

export const ButtonCancel = styled(SecondaryButton)`
  width: 140px;
  height: 40px;
`;
