/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import { DemoDirection, Geofence } from "declarations";
import { v4 as uuidv4 } from "uuid";
import Map from "Components/Map";

import { Map as MapType } from "leaflet";
import { FeatureGroup, Marker, Polyline } from "react-leaflet";
import GeofencePolygon from "Components/Map/Geofences/Geofence";

import toasts from "Shared/toasts";
import theme from "Styles/theme";
import { deleteDemoDirection, getDemoDirections } from "Api/DemoDirections";
import CrudRoutes from "Components/Map/DemoDirections";
import { formatCoords } from "Shared/utils";

const RotasDemo = () => {
  const [map, setMap] = useState<MapType | null>(null);
  const [center, setCenter] = useState<[number, number]>([
    -19.9530767, -43.953214,
  ]);
  const [routes, setRoutes] = useState<DemoDirection[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRoutes, setTotalRoutes] = useState(0);
  const [path, setPath] = useState<[number, number][] | null>(null);
  const [routeSelected, setRouteSelected] = useState<Geofence[]>([]);

  const positions = path && formatCoords(path);

  const showRoute = (value: Geofence[]) => {
    setRouteSelected(value);
  };

  const showPath = (value: [number, number][]) => {
    setPath(value);
  };

  const fetchDemoDirections = async () => {
    try {
      setLoading(true);
      const response = await getDemoDirections();
      setTotalRoutes(response.length);
      setRoutes(response);
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDemoDirections();
  }, []);

  useEffect(() => {
    const successCallback = (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      setCenter([latitude, longitude]);
      map?.flyTo([latitude, longitude]);
    };

    navigator.geolocation.getCurrentPosition(successCallback);
  }, []);

  const removeRoute = async (id: string) => {
    try {
      await deleteDemoDirection(id);
      const newRoutes = routes.filter((route) => route._id !== id);
      setRoutes(newRoutes);
      setTotalRoutes(totalRoutes - 1);
      toasts.success("Rota removida com sucesso!");
    } catch (error) {
      toasts.error((error as Error).message);
    }
  };

  return (
    <Map
      title="Rotas de Demonstração"
      subtitle={`(${totalRoutes} cadastros)`}
      center={center}
      zoom={13}
      whenCreated={(createdMap) => setMap(createdMap)}
      overlay={
        <CrudRoutes
          directions={routes}
          remove={removeRoute}
          loading={loading}
          map={map}
          selectPath={showPath}
          fetchDemoDirections={fetchDemoDirections}
          selectedRoute={showRoute}
        />
      }
      positionZoomControl="bottomright"
    >
      {path && positions && (
        <>
          <Marker position={positions[0]} />
          <Marker position={positions.at(-1)!} />
          <Polyline
            pathOptions={{ color: theme.colors.interactive.secondary }}
            positions={positions}
          />
        </>
      )}
      <FeatureGroup>
        {routeSelected.map((geofence) => {
          return (
            <GeofencePolygon
              key={geofence?._id || uuidv4()}
              geofence={geofence}
              colorPolygon={theme.colors.denotative.success}
            />
          );
        })}
      </FeatureGroup>
    </Map>
  );
};

export default RotasDemo;
