import React, { createContext, useEffect, useState } from "react";

import { User } from "declarations";
import { useHistory } from "react-router-dom";
import FullPageSpinner from "BaseComponents/Spinners/FullPageSpinner";
import feathers from "Api/Feathers";
import toasts from "Shared/toasts";

interface IUserContext {
  isSigned: boolean;
  isAdmin: boolean;
  user: User | null;
  login: (email: string, senha: string) => Promise<any>;
  logout: () => void;
}

const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const isAdmin = !!user?.roles.find((value) => value === "admin");
  const isSigned = !!user;
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const login = async (email: string, senha: string) =>
    feathers
      .authenticate({
        strategy: "local",
        email,
        password: senha,
      })
      .then((props) => {
        setUser(props.user);
        history.push("/dashboard");
      })
      .then(() => toasts.dismiss())
      .catch((error) => toasts.error("Erro ao tentar fazer login"));

  const logout = async () => {
    feathers.logout().then(() => {
      setUser(null);
      history.push("/login");
    });
  };

  useEffect(() => {
    feathers
      .reAuthenticate()
      .then((props) => {
        setUser(props.user);
      })
      .catch(() => {
        history.push("login");
      })
      .finally(() => setIsLoading(false));
  }, [history]);

  return (
    <UserContext.Provider
      value={{
        isSigned,
        isAdmin,
        user,
        login,
        logout,
      }}
    >
      {isLoading ? <FullPageSpinner /> : children}
    </UserContext.Provider>
  );
};

export default UserContext;
