import React, { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import theme from "Styles/theme";
import Search, { DateRangeType } from ".";
import { IFilter } from "..";

interface useSearchDateProps {
  filters: IFilter[];
  setFilters: React.Dispatch<React.SetStateAction<IFilter[]>>;
  name?: string;
  defaultRange?: DateRangeType;
}

const useSearchDate = ({
  filters,
  setFilters,
  name = "date",
  defaultRange,
}: useSearchDateProps) => {
  const [openSearchDate, setSearchDate] = useState(false);
  const [dateRange, setDateRange] = useState<DateRangeType | null>(
    defaultRange || null
  );

  const clearFilters = () => {
    setDateRange(null);
  };

  const handleOpen = () => {
    setSearchDate(true);
  };

  const tagDate = useCallback(() => {
    const index = filters.findIndex((value) => value.name === name);

    if (index === -1) {
      if (dateRange) {
        const filterDate: IFilter = {
          label: `${dayjs(dateRange.startDate).format("DD-MM")} à ${dayjs(
            dateRange.endDate
          ).format("DD-MM")}`,
          name,
          clear: clearFilters,
          open: handleOpen,
        };

        setFilters([filterDate, ...filters]);
      }
    } else if (!dateRange) {
      const listFilters = [...filters];
      listFilters.splice(index, 1);
      setFilters(listFilters);
    } else {
      const listFilters = [...filters];
      listFilters[index] = {
        label: `${dayjs(dateRange.startDate).format("DD-MM")} à ${dayjs(
          dateRange.endDate
        ).format("DD-MM")}`,
        name,
        clear: clearFilters,
        open: handleOpen,
      };
      setFilters(listFilters);
    }
  }, [dateRange, name, setFilters]);

  const SearchDateRange = () => (
    <Search
      open={openSearchDate}
      onClose={() => setSearchDate(false)}
      onChange={(value) => setDateRange(value)}
    />
  );

  return {
    SearchDateRange,
    dateRange,
    tagDate,
    setSearchDate,
    openSearchDate,
  };
};

export default useSearchDate;
