import React, { useEffect, useState } from "react";
import { Route as Router, Redirect, RouteProps } from "react-router-dom";
import LayoutDefault from "Pages/__Layout/Default";

interface IRoute extends RouteProps {
  signed: boolean;
  isPrivate: boolean;
  isMap?: boolean;
}

const Route = ({
  isMap = false,
  component,
  signed,
  isPrivate,
  ...rest
}: IRoute) => {
  if (!isPrivate && signed) {
    if (rest.path !== "/") return null;
  }

  if (isPrivate && !signed) {
    return <Redirect to="/login" />;
  }
  const Component = component || (() => <div />);

  const render = (props: any) => {
    if (props.match.path === "/login") {
      return <Component {...props} />;
    }
    if (signed) {
      return (
        <LayoutDefault>
          <Component {...props} />
        </LayoutDefault>
      );
    }

    return null;
  };

  return <Router {...rest} render={render} />;
};

export default Route;
