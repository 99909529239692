/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { Map as MapType } from "leaflet";
import { DemoDirection, Geofence } from "declarations";
import Button from "BaseComponents/Buttons/HeaderButton";
import { errorSentences } from "Shared/sentences";
import {
  formatCoords,
  generateRandomPlaque,
  getGeofencesTrajectory,
} from "Shared/utils";
import { postDemoDirection, updateDemoDirection } from "Api/DemoDirections";
import SimpleSpinner from "BaseComponents/Spinners/SimpleSpinner";
import { v4 as uuidv4 } from "uuid";
import toasts from "Shared/toasts";
import { getDirection } from "Api/MapBox/Directions";

import {
  Wrapper,
  ListWrapper,
  Label,
  DeleteIcon,
  ItemWrapper,
  Header,
  List,
  StyledAvatar,
  AddIcon,
  LabelWrapper,
} from "./styles";
import Create from "./Create";
import Edit from "./Edit";

interface CrudRoutesProps {
  directions: DemoDirection[];
  remove?: (id: string) => void;
  loading: boolean;
  map: MapType | null;
  selectPath: (path: [number, number][]) => void;
  fetchDemoDirections?: () => void;
  selectedRoute?: (values: Geofence[]) => void;
}

const CrudRoutes = ({
  directions,
  remove,
  loading,
  map,
  selectPath,
  selectedRoute = () => {},
  fetchDemoDirections = () => {},
}: CrudRoutesProps) => {
  const [stateCrud, setStateCrud] = useState<"list" | "edit" | "new">("list");
  const [routeEdit, setRouteEdit] = useState<DemoDirection | null>(null);

  const handleClick = (direction: DemoDirection) => {
    selectPath(direction.path.coordinates);
    selectedRoute(direction.geofences);
    map?.flyToBounds(formatCoords(direction.path.coordinates));
  };

  const createDestination = async (name: string, geofences: Geofence[]) => {
    if (stateCrud === "new") {
      const mapBoxDirection = await getDirection(
        getGeofencesTrajectory(geofences)
      );

      if (mapBoxDirection.routes.length === 0) {
        throw new Error(errorSentences.noRoute);
      }
      const newDestination: DemoDirection = {
        plaque: generateRandomPlaque(),
        name,
        geofences,
        path: mapBoxDirection.routes[0].geometry,
      };

      try {
        await postDemoDirection(newDestination);
        fetchDemoDirections();
        setStateCrud("list");
      } catch (error) {
        toasts.error((error as Error).message);
      }
    }
  };

  const cancelCreate = () => {
    setStateCrud("list");
  };

  const cancelEdit = () => {
    setRouteEdit(null);
  };

  const onSubmit = (data: any) => {
    createDestination(data.name, data.route);
  };

  const editSubmit = async (data: any) => {
    if (!routeEdit) return;

    let { path } = data;
    const isSameGeofences = routeEdit.geofences.every(
      (geofence, index) => geofence._id === data.geofences[index]?._id
    );

    if (!isSameGeofences) {
      path = await getDirection(getGeofencesTrajectory(data.geofences));
    }

    const newDirection: DemoDirection = {
      _id: routeEdit._id,
      plaque: data.plaque,
      name: data.name,
      geofences: data.route,
      path,
    };

    try {
      await updateDemoDirection(newDirection);
      await fetchDemoDirections();
      setRouteEdit(null);
    } catch (error) {
      toasts.error((error as Error).message);
    }
  };

  const content = () => {
    if (stateCrud === "edit" && routeEdit) {
      return (
        <Edit
          cancelEdit={cancelEdit}
          route={routeEdit}
          loading={loading}
          onSubmit={editSubmit}
        />
      );
    }

    if (stateCrud === "new") {
      return (
        <Create
          onSubmit={onSubmit}
          cancelCreate={cancelCreate}
          loading={loading}
          originsToCreate={selectedRoute}
        />
      );
    }

    return (
      <>
        <Header>Listagem</Header>

        <ListWrapper>
          <ItemWrapper>
            <Label>Adicionar</Label>

            <Button onClick={() => setStateCrud("new")}>
              <AddIcon onClick={() => setStateCrud("new")} />
            </Button>
          </ItemWrapper>
          <List>
            {directions.map((direction) => {
              return (
                <ItemWrapper key={direction._id || uuidv4()}>
                  <LabelWrapper onClick={() => handleClick(direction)}>
                    <StyledAvatar exceeded={false}>
                      {direction.name[0].toUpperCase()}
                    </StyledAvatar>
                    <Label>
                      {direction.name}
                    </Label>
                  </LabelWrapper>
                  <Button onClick={() => remove && remove(direction._id || "")}>
                    <DeleteIcon />
                  </Button>
                </ItemWrapper>
              );
            })}
            {loading && <SimpleSpinner />}
          </List>
        </ListWrapper>
      </>
    );
  };

  return <Wrapper>{content()}</Wrapper>;
};

export default CrudRoutes;
