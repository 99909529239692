import { yupResolver } from "@hookform/resolvers/yup";
import { DemoDirection } from "declarations";
import React from "react";
import { useForm } from "react-hook-form";
import {
  ButtonAction,
  ButtonsWrapper,
  EditWrapper,
  InputName,
} from "../styles";
import { DemoDirectionValidation } from "../Validation";

interface FormShape {
  name: string;
}

interface EditProps {
  onSubmit: (data: FormShape) => void;
  loading: boolean;
  route: DemoDirection;
  cancelEdit: () => void;
}

const Edit = ({ onSubmit, loading, cancelEdit, route }: EditProps) => {
  const { register, handleSubmit, errors } = useForm<FormShape>({
    resolver: yupResolver(DemoDirectionValidation),
    defaultValues: {
      name: route.name,
    },
  });

  const submit = handleSubmit(async (data) => {
    if (loading) return;

    onSubmit({ ...data });
  });

  return (
    <EditWrapper onSubmit={submit}>
      <InputName
        label="Nome da rota"
        type="text"
        name="name"
        errorText={errors.name?.message || ""}
        ref={register()}
      />

      <ButtonsWrapper>
        <ButtonAction onClick={cancelEdit}>Cancelar</ButtonAction>
        <ButtonAction type="submit" isLoading={loading}>
          {" "}
          Salvar
        </ButtonAction>
      </ButtonsWrapper>
    </EditWrapper>
  );
};

export default Edit;
