import React, { useState, useCallback } from "react";
import sharedSentences from "Shared/sentences";
import Search from ".";
import { IFilter } from "..";

interface useSearchMachinesProps {
  filters: IFilter[];
  setFilters: React.Dispatch<React.SetStateAction<IFilter[]>>;
}

const useSearchMachines = ({ filters, setFilters }: useSearchMachinesProps) => {
  const [openSearchMachines, setSearchMachines] = useState(false);
  const [filtersMachines, setFiltersMachines] = useState<string[]>([]);

  const clearFilters = () => {
    setFiltersMachines([]);
  };

  const handleOpen = () => {
    setSearchMachines(true);
  };

  const tagMachine = useCallback(() => {
    const index = filters.findIndex((value) => value.name === "machines");

    if (index === -1) {
      if (filtersMachines.length > 0) {
        const filterMachine: IFilter = {
          label: sharedSentences.trucks,
          name: "machines",
          clear: clearFilters,
          open: handleOpen,
        };

        setFilters([filterMachine, ...filters]);
      }
    } else if (filtersMachines.length === 0) {
      const listFilters = [...filters];
      listFilters.splice(index, 1);
      setFilters(listFilters);
    }
  }, [filtersMachines.length]);

  const SearchMachines = () => (
    <Search
      open={openSearchMachines}
      onClose={() => setSearchMachines(false)}
      onApplyFilters={(value) => setFiltersMachines(value)}
    />
  );

  return {
    SearchMachines,
    filtersMachines,
    tagMachine,
    setSearchMachines,
    openSearchMachines,
    clearFilters,
  };
};

export default useSearchMachines;
