import Table, {
  Col as ColTable,
  Option as OptionTable,
} from "BaseComponents/Table";
import dayjs from "dayjs";
import Pagination from "BaseComponents/Table/Pagination";
import { getForbiddenEvent, getForbiddenEventCSV } from "Api/ForbiddenEvent";
import TableOptions from "Components/TableOptions";
import PageWrapper from "Components/Wrappers/PageWrapper";
import { ForbiddenEvent } from "declarations";
import useCrud from "Hooks/useCrud";
import React, { useCallback, useEffect } from "react";
import toasts from "Shared/toasts";
import useSearchDate from "Components/TableOptions/SearchDate/useSearchDate";
import useSearchGeofences from "Components/TableOptions/SearchGeofences/useSearchGeofences";
import useSearchMachines from "Components/TableOptions/SearchMachines/useSearchMachines";
import { downloadFile } from "Shared/utils";
import useSearchClients from "Components/TableOptions/SearchClients/useSearchClients";
import sharedSentences from "Shared/sentences";
import theme from "Styles/theme";
import { DateRangeType } from "Components/TableOptions/SearchDate";
import { ContentWrapper } from "./styles";

const fields = [
  [sharedSentences.truck, "machine.plaque"],
  ["Alvo", "geofence.name"],
  ["Velocidade", "speed"],
  ["Data", "date"],
  [sharedSentences.telemetry, "machine.telemetry"],
  [sharedSentences.client, "machine.client"],
];

const optionsRowsPerPage = [10, 20, 30];

const LocalProibido = () => {
  const {
    listCrud,
    setListCrud,
    totalCrud,
    setTotalCrud,
    loading,
    setLoading,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    filters,
    setFilters,
  } = useCrud<ForbiddenEvent>();

  const { SearchGeofences, filtersGeofences, tagGeofence, setSearchGeofences } =
    useSearchGeofences({ filters, setFilters });

  const { SearchMachines, filtersMachines, setSearchMachines, tagMachine } =
    useSearchMachines({ filters, setFilters });

  const { SearchDateRange, dateRange, setSearchDate, tagDate } = useSearchDate({
    filters,
    setFilters,
  });

  const { SearchClients, filtersClients, setSearchClients, tagClients } =
    useSearchClients({ filters, setFilters });

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const range = dateRange
        ? [dateRange.startDate.toDateString(), dateRange.endDate.toDateString()]
        : undefined;
      const response = await getForbiddenEvent(
        rowsPerPage,
        page * rowsPerPage,
        filtersMachines,
        filtersGeofences,
        filtersClients,
        range
      );
      setListCrud(response.data);
      setTotalCrud(response.total);
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    dateRange,
    rowsPerPage,
    page,
    filtersMachines,
    filtersGeofences,
    setListCrud,
    setTotalCrud,
    filtersClients,
  ]);

  const fetchDataCSV = async () => {
    try {
      setLoading(true);
      const range = dateRange
        ? [dateRange.startDate.toDateString(), dateRange.endDate.toDateString()]
        : undefined;
      const response = await getForbiddenEventCSV(
        fields,
        rowsPerPage,
        page * rowsPerPage,
        filtersMachines,
        filtersGeofences,
        range
      );

      downloadFile(response, "LocalProibido.csv");
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    tagMachine();
  }, [tagMachine]);

  useEffect(() => {
    tagGeofence();
  }, [tagGeofence]);

  useEffect(() => {
    tagDate();
  }, [tagDate]);

  useEffect(() => {
    tagClients();
  }, [tagClients]);

  const colsTable: ColTable[] = [
    { name: "machine.name", label: sharedSentences.truck, align: "left" },
    { name: "geofence.name", label: "Alvo", align: "left" },
    { name: "speed", label: "Velocidade", align: "left" },
    { name: "date", label: "Data", align: "left" },
    {
      name: "machine.telemetry",
      label: sharedSentences.telemetry,
      align: "left",
    },
    { name: "machine.client", label: sharedSentences.client, align: "left" },
  ];

  const itemsFilter = [
    {
      label: sharedSentences.trucks,
      action: () => setSearchMachines(true),
    },
    {
      label: "Alvos",
      action: () => setSearchGeofences(true),
    },
    {
      label: "Data",
      action: () => setSearchDate(true),
    },
    {
      label: sharedSentences.client,
      action: () => setSearchClients(true),
    },
  ];

  const handlePage = (pageNumber: number) => {
    setPage(pageNumber - 1);
  };

  const handleRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
  };

  const removeFilter = (index: number) => {
    if (filters[index].name === "machines") {
      setSearchMachines(true);
    }
    if (filters[index].name === "date") {
      setSearchDate(true);
    }
    if (filters[index].name === "geofences") {
      setSearchGeofences(true);
    }
  };

  return (
    <PageWrapper title="Local Proibido">
      <ContentWrapper>
        <TableOptions
          itemsFilter={itemsFilter}
          filtersTags={filters}
          removeTag={removeFilter}
          onExportToCSV={fetchDataCSV}
        />
        <Table loading={loading} cols={colsTable} data={listCrud} />
      </ContentWrapper>
      <Pagination
        count={totalCrud}
        onChangePage={handlePage}
        onChangeRowsPerPage={handleRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        optionsRowsPerPage={optionsRowsPerPage}
      />
      {SearchDateRange()}
      {SearchMachines()}
      {SearchGeofences()}
      {SearchClients()}
    </PageWrapper>
  );
};

export default LocalProibido;
