import { MapBoxDirection } from "declarations";
import { mapBoxApi } from "../Api";

export const getDirection = async (
  coords: number[][]
): Promise<MapBoxDirection> => {
  const params = new URLSearchParams();
  const COORDS_SEPARATOR = ";";
  const LAT_LONG_SEPARATOR = ",";

  const serializedCoords = encodeURIComponent(
    coords.map((coord) => coord.join(LAT_LONG_SEPARATOR)).join(COORDS_SEPARATOR)
  );

  params.set("alternatives", "false");
  params.set("geometries", "geojson");
  params.set("overview", "simplified");
  params.set("steps", "false");
  params.set("notifications", "none");
  params.set("access_token", process.env.REACT_APP_MAPBOX_TOKEN!);

  const { data } = await mapBoxApi.get<MapBoxDirection>(
    `/directions/v5/mapbox/driving/${serializedCoords}?${params}`
  );

  return data;
};
