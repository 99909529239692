import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const mapBoxApi = axios.create({
  baseURL: "https://api.mapbox.com",
})

export default api;
