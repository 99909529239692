import Table, {
  Col as ColTable,
  Option as OptionTable,
} from "BaseComponents/Table";
import Pagination from "BaseComponents/Table/Pagination";
import TableOptions from "Components/TableOptions";
import PageWrapper from "Components/Wrappers/PageWrapper";
import { ExceedSpeed } from "declarations";
import useCrud from "Hooks/useCrud";
import React, { useCallback, useEffect } from "react";
import useSearchGeofences from "Components/TableOptions/SearchGeofences/useSearchGeofences";
import useSearchMachines from "Components/TableOptions/SearchMachines/useSearchMachines";
import useSearchDate from "Components/TableOptions/SearchDate/useSearchDate";
import useTruckPath from "Components/Map/TruckPath/useTruckPath";
import { MapOutlined } from "@material-ui/icons";

import useExceedSpeed from "Hooks/useExceedSpeed";
import useSearchClients from "Components/TableOptions/SearchClients/useSearchClients";
import sharedSentences from "Shared/sentences";
import { ContentWrapper } from "./styles";

const optionsRowsPerPage = [10, 20, 30];

const ExcessoDeVelocidade = () => {
  const {
    listCrud,
    setListCrud,
    totalCrud,
    setTotalCrud,
    loading,
    setLoading,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    filters,
    setFilters,
  } = useCrud<ExceedSpeed>();

  const { SearchGeofences, filtersGeofences, tagGeofence, setSearchGeofences } =
    useSearchGeofences({ filters, setFilters });

  const {
    SearchMachines,
    filtersMachines,
    setSearchMachines,
    tagMachine,
    clearFilters,
  } = useSearchMachines({ filters, setFilters });

  const { SearchClients, filtersClients, setSearchClients, tagClients } =
    useSearchClients({ filters, setFilters });

  const { SearchDateRange, dateRange, setSearchDate, tagDate } = useSearchDate({
    filters,
    setFilters,
  });

  const { TruckPathModal, onSearchOnModal } = useTruckPath({});

  const { fetchData, fetchDataCSV } = useExceedSpeed({
    dateRange,
    filtersGeofences,
    filtersMachines,
    filtersClients,
    page,
    rowsPerPage,
    setListCrud,
    setLoading,
    setTotalCrud,
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    tagMachine();
  }, [tagMachine]);

  useEffect(() => {
    tagGeofence();
  }, [tagGeofence]);

  useEffect(() => {
    tagDate();
  }, [tagDate]);

  useEffect(() => {
    tagClients();
  }, [tagClients]);

  const colsTable: ColTable[] = [
    { name: "machine.name", label: sharedSentences.truck, align: "left" },
    { name: "geofence.name", label: "Alvo", align: "left" },
    {
      name: "geofence.maxSpeed",
      label: "L. Vel. Alvo",
      align: "left",
    },
    { name: "speed", label: "Velocidade", align: "left" },
    { name: "date", label: "Data", align: "left" },
    {
      name: "machine.telemetry",
      label: sharedSentences.telemetry,
      align: "left",
    },
    { name: "machine.client", label: sharedSentences.client, align: "left" },
  ];

  const itemsFilter = [
    {
      label: sharedSentences.trucks,
      action: () => setSearchMachines(true),
    },
    {
      label: "Alvos",
      action: () => setSearchGeofences(true),
    },
    {
      label: "Data",
      action: () => setSearchDate(true),
    },
    {
      label: sharedSentences.clients,
      action: () => setSearchClients(true),
    },
  ];

  const handlePage = (pageNumber: number) => {
    setPage(pageNumber - 1);
  };

  const handleRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
  };

  const searchPath = async (data: any) => {
    const dateIn = new Date(data.date);
    dateIn.setHours(dateIn.getHours() - 1);
    const dateEnd = new Date(data.date);
    dateEnd.setHours(dateEnd.getHours() + 1);

    await onSearchOnModal(data.machine.plaque, dateIn, dateEnd);
  };

  const optionsTable: OptionTable[] = [
    {
      label: "Ver trajeto",
      action: (itemData) => searchPath(itemData),
      Icon: <MapOutlined />,
    },
  ];

  return (
    <PageWrapper title="Excesso de Velocidade">
      <ContentWrapper>
        <TableOptions
          itemsFilter={itemsFilter}
          filtersTags={filters}
          onExportToCSV={fetchDataCSV}
        />
        <Table
          loading={loading}
          cols={colsTable}
          data={listCrud}
          options={optionsTable}
        />
      </ContentWrapper>
      <Pagination
        count={totalCrud}
        onChangePage={handlePage}
        onChangeRowsPerPage={handleRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        optionsRowsPerPage={optionsRowsPerPage}
      />
      {SearchDateRange()}
      {SearchMachines()}
      {SearchGeofences()}
      {SearchClients()}
      {TruckPathModal()}
    </PageWrapper>
  );
};

export default ExcessoDeVelocidade;
