import { H3SemiBold } from "BaseComponents/Typographies";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  top: 10vh;

  left: 0;
  right: 0;

  margin: auto;
  width: max-content;
  border-radius: 8px;

  z-index: 801;
  height: auto;
  background: white;

  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px 30px;
`;

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  width: 50%;
  margin-top: 10px;
`;

export const Label = styled(H3SemiBold)`
  color: ${(props) => props.theme.colors.layout.blackPrimary};
`;
