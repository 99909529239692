const markerSituations = {
  normal: "Normal",
  exceeded: "Tempo excedido",
  exceededSpeed: "Velocidade excedida",
  longTimeUpdate: "Atualizado à muito tempo",
  exceededTimeAndSpeed: "Velocidadade e tempo excedidos",
  internMachines: "Pás carregadeiras",
};

const sharedSentences = {
  truck: "Placa",
  trucks: "Placas",
  client: "Transportadora",
  clients: "Transportadoras",
  telemetry: "Telemetria",
  telemetries: "Telemetrias",
  markerSituations,
};

export const errorSentences = {
  noRoute: "Nenhuma rota encontrada"
}

export default sharedSentences;
